import log from 'loglevel';


export const getUserByEmail = async (email) => {
    console.log(JSON.stringify(user_details.user_details));

    const response = await fetch('/v1/api/users/email/'+email, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const result = await response.json();
    return result

};


export default {
    getUserByEmail
}